import React, { FC } from "react";
import { Box } from "@mui/material";

export interface Props {
  size?: "small" | "large";
}
export const Logo: FC<Props> = ({ size }) => (
  <Box
    aria-label="Cleverest logo"
    component={"a"}
    href={"/"}
    width={{ xs: "152px", sm: "175px" }}
    height={{ xs: "26px", sm: "30px" }}
    sx={{
      backgroundImage: `url(/logo.svg)`,
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    }}
  />
);
