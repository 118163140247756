import { ApolloClient, InMemoryCache } from "@apollo/client";

export const DIRECTUS_URL = "https://cms.cleverest.cz";
export const API_URL = `${DIRECTUS_URL}/graphql`;

export const client = new ApolloClient({
  uri: API_URL,
  cache: new InMemoryCache(),
});

export const fileUrl = (path: string, transformation?: string) =>
  `${DIRECTUS_URL}/assets/${path}${transformation ? `?key=${transformation}` : ""}`;
