import * as React from "react";
import { FC, useMemo } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Box, SxProps, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ExpandIcon } from "../icons/ExpandIcon";

const LanguageSwitcher: FC<{ sx?: SxProps }> = ({ sx }) => {
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLngChange = (lng: "en" | "cs") => {
    handleClose();
    i18n.changeLanguage(lng);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const lng = useMemo(
    () => (i18n.language.startsWith("en") ? "en" : "cs"),
    [i18n.language],
  );

  if (!lng) {
    return <></>;
  } else {
    return (
      <Box sx={sx}>
        <Button
          id="language-menu"
          aria-controls={open ? "lng-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          endIcon={<ExpandIcon />}
        >
          <Typography fontSize="16px" fontWeight={600} color={"#fff"}>
            {lng === "cs" ? "CZ" : "EN"}
          </Typography>
        </Button>
        <Menu
          id="lng-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "language-menu",
          }}
          sx={{ "& .MuiPaper-root": { minWidth: "120px" } }}
        >
          <MenuItem
            onClick={() => handleLngChange("cs")}
            selected={lng === "cs"}
          >
            CZ
          </MenuItem>
          <MenuItem
            onClick={() => handleLngChange("en")}
            selected={lng === "en"}
          >
            EN
          </MenuItem>
        </Menu>
      </Box>
    );
  }
};

export default LanguageSwitcher;
