import React, { FC } from "react";
import { SvgIcon } from "@mui/material";

export const ExpandIcon: FC<{ color?: string }> = ({ color }) => (
  <SvgIcon
    viewBox="0 0 12 6"
    sx={{ fontSize: "11px !important", color: color ?? "white" }}
  >
    <path
      d="M0.543735 0L0 0.491453L4.72813 4.76496L5 5L5.27187 4.76496L10 0.491453L9.45627 0L5 4.02778L0.543735 0Z"
      fill={color ?? "white"}
    />
  </SvgIcon>
);
