import { Container, styled } from "@mui/material";

const MainContainer = styled(Container)(({ theme }) => ({
  overflow: "visible",

  [theme.breakpoints.up("lg")]: {
    maxWidth: "1280px",
  },
}));

export default MainContainer;
