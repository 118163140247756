import React, { FC } from "react";
import { Stack, Toolbar, useMediaQuery, useTheme } from "@mui/material";
import { Logo } from "../components/Logo";
import { NavigationMenu } from "../components/NavigationMenu";
import MainContainer from "../components/MainContainer";

export const Navigation: FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Toolbar
      sx={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <MainContainer sx={{ paddingRight: "0 !important" }}>
        <Stack
          direction={"row"}
          spacing={8}
          alignItems={"center"}
          justifyContent={{ xs: "space-between", md: "flex-start" }}
        >
          <Logo />
          <NavigationMenu />
        </Stack>
      </MainContainer>
    </Toolbar>
  );
};
