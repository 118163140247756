import { IconButton, IconButtonProps, useTheme } from "@mui/material";
import { FC } from "react";
import { CloseIcon } from "../icons/CloseIcon";

export const CloseButton: FC<IconButtonProps> = ({ sx, ...props }) => {
  const theme = useTheme();

  return (
    <IconButton
      {...props}
      size={"large"}
      disableRipple
      sx={{ ...sx, color: theme.palette.primary.contrastText }}
    >
      <CloseIcon />
    </IconButton>
  );
};
