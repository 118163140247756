import React from "react";
import {
  Drawer,
  Link,
  Stack,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import navigation from "../constants/navigation";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "./LanguageSwitcher";
import { MenuButton } from "./MenuButton";
import { useLocation } from "react-router-dom";
import MainContainer from "./MainContainer";
import { CloseButton } from "./CloseButton";

export const NavigationMenu = () => {
  const theme = useTheme();
  const location = useLocation();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const navigateTo = (e: any, route: string, delay = 0) => {
    setIsMenuOpen(false);
    if (location.pathname === "/" && route.startsWith("/#")) {
      e.preventDefault();
      const documentId = route.replace(/^\/|#/g, "");
      const scrollTo = document.getElementById(documentId);
      if (scrollTo) {
        setTimeout(function () {
          scrollTo.scrollIntoView({ behavior: "smooth", block: "center" });
        }, delay);
      }
    }
  };

  return (
    <>
      <Stack
        direction={"row"}
        alignItems={"center"}
        spacing={5}
        sx={{
          alignSelf: "center",
          minWidth: { md: "460", lg: "600px" },
          maxWidth: "600px",
          display: { xs: "none", md: "flex" },
        }}
      >
        {navigation.map((route) => (
          <Link
            onClick={(e) => navigateTo(e, route.route, 100)}
            key={route.name}
            href={route.route}
            className={"nav-link"}
            underline={"none"}
            noWrap
          >
            {t(route.label)}
          </Link>
        ))}
        <LanguageSwitcher />
      </Stack>
      <Stack
        direction={"row"}
        alignItems={"center"}
        spacing={5}
        sx={{
          minWidth: { md: "460", lg: "600px" },
          maxWidth: "600px",
          display: { xs: "flex", md: "none" },
        }}
      >
        <MenuButton aria-label="Menu" onClick={() => setIsMenuOpen(true)} />
        <Drawer
          anchor={"right"}
          open={isMobile && isMenuOpen}
          onClose={() => setIsMenuOpen(false)}
          sx={{
            "& .MuiPaper-root": {
              borderRadius: 0,
              backgroundColor: theme.palette.primary.main,
              width: { xs: "100%", sm: "320px" },
            },
          }}
        >
          <Toolbar
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: 5.75,
            }}
          >
            <MainContainer sx={{ paddingRight: "0 !important" }}>
              <Stack
                direction={"row"}
                spacing={8}
                alignItems={"center"}
                justifyContent={"flex-end"}
              >
                <CloseButton onClick={() => setIsMenuOpen(false)} />
              </Stack>
            </MainContainer>
          </Toolbar>
          <Stack spacing={4}>
            {navigation.map((route) => (
              <Link
                onClick={(e) => navigateTo(e, route.route, 100)}
                key={route.name}
                href={route.route}
                className={"nav-link"}
                underline={"none"}
                noWrap
                sx={{ textAlign: "center" }}
              >
                {t(route.label)}
              </Link>
            ))}
            <LanguageSwitcher
              sx={{ display: "flex", justifyContent: "center" }}
            />
          </Stack>
        </Drawer>
      </Stack>
    </>
  );
};
