import React, { useCallback } from "react";

export type TargetAudience = "investor" | "emitent";

export interface TargetAudienceData {
  target_audience?: {
    id: string;
  } | null;
}

export type WithTargetAudience<T> = (T & TargetAudienceData) | null;

export const TargetAudienceContext = React.createContext<{
  targetAudience: TargetAudience;
  setTargetAudience: (audience: TargetAudience) => void;
  getAudienceData: <T extends TargetAudienceData>(data: T[]) => T | null;
}>({
  targetAudience: "investor",
  setTargetAudience: (audience: TargetAudience) => {
    return;
  },
  getAudienceData: <T extends TargetAudienceData>(data: T[]): T | null => {
    return data?.[0];
  },
});

export const TargetAudienceContextProvider: React.FC<any> = ({ children }) => {
  const [targetAudience, setTargetAudience] =
    React.useState<TargetAudience>("investor");

  const getAudienceData = useCallback(
    <T extends TargetAudienceData>(data: T[]): T | null => {
      return (
        data?.find((x) => x.target_audience?.id === targetAudience) ?? null
      );
    },
    [targetAudience],
  );

  return (
    <TargetAudienceContext.Provider
      value={{ targetAudience, setTargetAudience, getAudienceData }}
    >
      {children}
    </TargetAudienceContext.Provider>
  );
};

export const useTargetAudience = () => {
  const context = React.useContext(TargetAudienceContext);
  if (!context) {
    throw new Error(
      "useTargetAudience must be used within a TargetAudienceContextProvider",
    );
  }
  return context;
};
