import React from "react";
import {
  TargetAudience,
  useTargetAudience,
} from "../contexts/TargetAudienceContext";
import {
  Box,
  Stack,
  Tab,
  Tabs,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import MainContainer from "../components/MainContainer";

export const TargetAudienceSwitch: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const { targetAudience, setTargetAudience } = useTargetAudience();

  const handleChange = (e: React.SyntheticEvent, value: TargetAudience) => {
    setTargetAudience(value);
  };

  return (
    <Toolbar
      sx={{
        display: "flex",
        backgroundColor: "background.default",
        minHeight: "46px !important",
        maxHeight: "46px !important",
        justifyContent: "center",
      }}
    >
      <MainContainer>
        <Stack
          direction={"row"}
          spacing={8}
          justifyContent={{ xs: "center", sm: "flex-start" }}
        >
          {!isMobile && (
            <Box
              component={"a"}
              href={"/"}
              width={{ xs: "152px", md: "175px" }}
              height={{ xs: "26px", md: "30px" }}
            />
          )}
          <Tabs
            value={targetAudience}
            onChange={handleChange}
            aria-label="basic tabs example"
            centered={isMobile}
            sx={{ paddingLeft: { xs: "40px", sm: "0" } }}
          >
            <Tab label={t("navigace.proInvestory")} value={"investor"} />
            <Tab label={t("navigace.proEmitenty")} value={"emitent"} />
          </Tabs>
        </Stack>
      </MainContainer>
    </Toolbar>
  );
};
