import React, { FC } from "react";
import { SvgIcon } from "@mui/material";

export const MenuIcon: FC<{ color?: string }> = () => (
  <SvgIcon viewBox="0 0 28 24">
    <path d="M2 2H26" stroke="white" strokeWidth="3" strokeLinecap="round" />
    <path d="M2 12H26" stroke="white" strokeWidth="3" strokeLinecap="round" />
    <path d="M2 22H26" stroke="white" strokeWidth="3" strokeLinecap="round" />
  </SvgIcon>
);
