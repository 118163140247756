export default [
  {
    name: "nase-sluzby",
    route: "/#nase-sluzby",
    label: "navigace.naseSluzby",
  },
  {
    name: "dotazy",
    route: "/#dotazy",
    label: "navigace.dotazy",
  },
  {
    name: "o-nas",
    route: "/#o-nas",
    label: "navigace.oNas",
  },
  {
    name: "kontakty",
    route: "/#kontakty",
    label: "navigace.kontakty",
  },
];
