import { createTheme } from "@mui/material";

export const pxToRem = (px: number) => `${px / 16}rem`;

const Theme = createTheme({
  palette: {
    primary: {
      main: "#009DB9",
      dark: "#255961",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#fff",
    },
    text: {
      primary: "#1C1D21",
    },
    background: {
      default: "#fff",
      paper: "#F5F9FA",
    },
  },
  typography: {
    fontFamily: "TT Commons, sans-serif",
    h2: {
      fontSize: pxToRem(48),
      lineHeight: "98%",
      fontWeight: 600,
    },
    h3: {
      fontSize: pxToRem(32),
      lineHeight: "160%",
      fontWeight: 600,
    },
    h4: {
      fontSize: pxToRem(28),
      lineHeight: "110%",
      fontWeight: 600,
    },
    h5: {
      fontSize: pxToRem(18),
      lineHeight: "150%",
      fontWeight: 700,
    },

    subtitle1: {
      fontSize: pxToRem(18),
      lineHeight: "130%",
      fontWeight: 400,
    },
    subtitle2: {
      fontSize: pxToRem(14),
      lineHeight: "150%",
      fontWeight: 400,
    },
    body1: {
      fontSize: pxToRem(21),
      lineHeight: "135%",
      fontWeight: 400,
    },
    body2: {
      fontSize: pxToRem(16),
      lineHeight: "135%",
      fontWeight: 400,
    },
  },
});

Theme.components = {
  MuiAppBar: {
    styleOverrides: {
      root: {
        backgroundColor: "transparent",
      },
    },
    defaultProps: {
      elevation: 0,
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: "8px",
        textTransform: "none",
        fontSize: pxToRem(16),
        fontWeight: 600,
      },
    },
    defaultProps: {
      disableElevation: true,
    },
  },
  MuiDialog: {
    styleOverrides: {
      paper: {
        borderRadius: "28px",
      },
      paperFullScreen: {
        borderRadius: 0,
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        "&.nav-link": {
          fontSize: pxToRem(18),
          fontWeight: 600,
          lineHeight: 1.23,
          color: Theme.palette.primary.contrastText,
        },
      },
    },
  },
  MuiSkeleton: {
    defaultProps: {
      animation: "wave",
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        color: Theme.palette.primary.main,
        textTransform: "none",
        fontSize: pxToRem(18),
        fontWeight: 400,
        "&.Mui-selected": {
          fontWeight: 600,
        },
        minWidth: "60px",
        padding: "10px 0",
        marginRight: Theme.spacing(6),
      },
    },
  },
  MuiToggleButton: {
    styleOverrides: {
      root: {
        borderRadius: "8px",
        "&.Mui-selected": {
          borderColor: Theme.palette.primary.main,
          backgroundColor: Theme.palette.primary.main,
          color: Theme.palette.primary.contrastText,
          "&:hover": {
            backgroundColor: Theme.palette.primary.dark,
          },
        },
        "&:hover": {
          borderColor: Theme.palette.primary.dark,
        },
      },
    },
  },
  MuiFilledInput: {
    styleOverrides: {
      root: {
        backgroundColor: Theme.palette.background.default,
        border: "1px solid rgba(151, 151, 151, 0.50)",
        borderRadius: "4px",

        "&.Mui-focused": {
          backgroundColor: Theme.palette.background.default,
        },
      },
    },
    defaultProps: {
      disableUnderline: true,
    },
  },
  MuiMenu: {
    styleOverrides: {
      list: {
        '&[role="menu"]': {
          backgroundColor: Theme.palette.primary.main,
          borderRadius: "12px",
        },
      },
      paper: {
        borderRadius: "12px",
      },
      root: {
        "& .MuiMenuItem-root": {
          fontSize: pxToRem(18),
          fontWeight: 400,
          color: Theme.palette.primary.contrastText,
          "&.Mui-selected": {
            fontWeight: 600,
          },
        },
      },
    },
  },
};

export default Theme;
