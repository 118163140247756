import { IconButton, IconButtonProps, useTheme } from "@mui/material";
import { MenuIcon } from "../icons/MenuIcon";
import { FC } from "react";

export const MenuButton: FC<IconButtonProps> = ({ sx, ...props }) => {
  const theme = useTheme();

  return (
    <IconButton
      {...props}
      size={"large"}
      disableRipple
      sx={{ ...sx, color: theme.palette.primary.contrastText }}
    >
      <MenuIcon />
    </IconButton>
  );
};
