import React from "react";
import { ApolloProvider } from "@apollo/client";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { client } from "./apiClient";
import { TargetAudienceContextProvider } from "./contexts/TargetAudienceContext";
import { TargetAudienceSwitch } from "./blocks/TargetAudienceSwitch";
import { AppBar } from "@mui/material";
import { Navigation } from "./blocks/Navigation";
import ElevationScroll from "./components/ElevationScroll";

const MainPage = React.lazy(() => import("./pages/MainPage"));
const Page = React.lazy(() => import("./pages/Page"));
const MandatoryInformationPage = React.lazy(
  () => import("./pages/MandatoryInformationPage"),
);
const FormPage = React.lazy(() => import("./pages/FormPage"));

function App() {
  return (
    <TargetAudienceContextProvider>
      <ApolloProvider client={client}>
        <Router>
          <ElevationScroll>
            <AppBar>
              <Routes>
                <Route path={"/"} element={<TargetAudienceSwitch />} />
              </Routes>
              <Navigation />
            </AppBar>
          </ElevationScroll>
          <Routes>
            <Route path={"/"} element={<MainPage />} />
            <Route
              path={"/povinne-informace"}
              element={<MandatoryInformationPage />}
            />
            <Route
              path={"/form/:id"}
              element={
                <React.Suspense fallback={<>...</>}>
                  <FormPage />
                </React.Suspense>
              }
            />
            <Route
              path={"/:id"}
              element={
                <React.Suspense fallback={<>...</>}>
                  <Page />
                </React.Suspense>
              }
            />
          </Routes>
        </Router>
      </ApolloProvider>
    </TargetAudienceContextProvider>
  );
}

export default App;
