import React, { FC } from "react";
import { SvgIcon } from "@mui/material";

export const CloseIcon: FC<{ color?: string }> = () => (
  <SvgIcon viewBox="0 0 30 30">
    <path
      d="M7 7L15 15M15 15L23 7M15 15L23 23M15 15L7 23"
      stroke="white"
      strokeWidth="3"
    />
  </SvgIcon>
);
